import axios from 'axios';

const DEVELOP = Boolean(process.env.REACT_APP_DEVELOP) === true;
const LOCAL_SERVER_URL = 'http://localhost:5000';
const url = DEVELOP ? LOCAL_SERVER_URL : '';

const getTrips = data => {
  return axios
    .post(url + "/api/trips", { data })
    .then(res => res.data);
};

const sendOrder = async data => {
  const result = axios
    .post(url + "/api/order", { data })
    .then(res => res);
  return result;
};

export default {
  trips: {
    get: getTrips
  },
  sendOrder
};
